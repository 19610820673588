.navbar-custom {
  /* margin-left: -51px; */
  /* width: calc(100vw - 16rem) ; */
  background-color: #c7f0bd;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
  border-bottom: 1px solid #6e4695;
}

.modal {
  z-index: 1050 !important; /* Ensure it's above the table */
  position: fixed; /* Keep the modal in a fixed position */
}

.modal-backdrop {
  z-index: 1040 !important; /* Ensure the backdrop is right below the modal */
}

textarea {
  margin-top: 10px;
  margin-left: -20px;
  width: 500px;
  height: 100px;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  /* background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07); */
  border-color: -moz-use-text-color #ffffff #ffffff -moz-use-text-color;
  border-image: none;
  border-radius: 6px 6px 6px 6px;
  border-style: none solid solid none;
  border-width: medium 1px 1px medium;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
  color: #555555;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 1.4em;
  padding: 5px 8px;
  transition: background-color 0.2s ease 0s;
}

textarea:focus {
  background: none repeat scroll 0 0 #ffffff;
  outline-width: 0;
}

.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
/* @media (max-width: 1844px) {
  .navbar-custom {
    margin-left: -10px;
  }
} */

@media (max-width: 1500px) {
  .navbar-custom {
    margin-left: -5px;
  }
}

.nav-text {
  color: #6e4695 !important;
  font-size: 1.3rem;
  font-weight: 600;
}

.table-text-purple {
  color: #6e4695 !important;
}

.btn-warning {
  background-color: #ffad05 !important;
  border: none;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 5px;
  text-transform: capitalize !important;
  margin-left: 10px;
}

.btn-yellow {
  background-color: #ffda3e !important;
  border: none;
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: 500;
  border-radius: 5px;
  text-transform: capitalize !important;
  margin-left: 10px;
}

.bg-purple {
  background-color: #6e4695 !important;
  color: #fff !important;
}

.btn-yellow:hover {
  background-color: #ffda3e !important;
  border: none;
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: 500;
  border-radius: 5px;
  text-transform: capitalize !important;
  margin-left: 10px;
}

.btn-warning:hover {
  background-color: #ffad05 !important;
  border: none;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 5px;
  margin-left: 10px;
}
