.duration-picker-container {
  font-family: "Arial", sans-serif;
  margin: 20px;
}

label {
  font-size: 16px;
  margin-bottom: 8px;
  display: block;
}

.duration-select-container {
  display: flex;
  justify-content: center;
}

.duration-select {
  font-size: 14px;
  padding: 8px;
  margin-right: 10px;
}

.selected-duration {
  font-size: 14px;
  margin-top: 10px;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
