.card-packing {
  background-color: #ffffff;
  height: 345px;
  width: 456px;
  border-radius: 33px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0px !important;
  margin-bottom: 20px !important;
}

.card-packing-home {
  background-color: #ffffff;
  height: 285px;
  width: 456px;
  border-radius: 33px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0px !important;
  margin-bottom: 20px !important;
}

.card-packing-rating-home {
  background-color: #ffffff;
  height: 285px;
  width: 430px;
  border-radius: 33px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0px !important;
  margin-bottom: 20px !important;
}

.card-packing-rating {
  background-color: #ffffff;
  height: 359px;
  width: 430px;
  border-radius: 33px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0px !important;
  margin-bottom: 20px !important;
}

.card-feedbacks {
  background-color: #ffffff;
  height: 740px;
  width: 928px;
  border-radius: 33px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0px !important;
}

.card-feedbacks-packing {
  background-color: #ffffff;
  height: 700px;
  width: 928px;
  border-radius: 33px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0px !important;
}

.card-ratings {
  background-color: #ffffff;
  height: 700px;
  width: 928px;
  border-radius: 33px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0px !important;
}

.dashboard-backgroud-color {
  background-color: #9c6eaa !important;
  height: 100vh;
}

.card-shift {
  background-color: #ffffff;
  height: 750px;
  margin-top: -234px;
  width: 911px;
  border-radius: 33px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0px !important;
  margin-bottom: 20px !important;
}

.card-order-rating {
  height: 750px;
  margin-top: -234px;
  width: 941px;
  padding: 0px !important;
  margin-bottom: 20px !important;
}
.user-icon {
  font-size: 40px;
  margin-right: 10px;
  margin-left: 10px;
}

.card-status-color {
  background-color: #ffffff;
  width: 936px;
  height: 500px;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0px !important;
  margin-bottom: 20px !important;
}

.card-clock {
  background-color: #f5f5f5f1;
  width: 490px;
  height: 170px;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 0px !important;
  margin-bottom: 10px !important;
}

.card-count {
  color: #fda000;
  font-size: 170px;
  font-weight: 600;
  margin: 0 auto;
}

.card-count-rating {
  color: #fda000;
  font-size: 130px;
  font-weight: 600;
  margin: 30px auto;
}
.card-count-rating-home {
  color: #fda000;
  font-size: 130px;
  font-weight: 600;
  margin: 10px auto;
}
.card-body-wrapper {
  position: relative;
}

.card-body-wrapper > h1 {
  display: inline-block;
}

.rating-percentage {
  position: absolute;
  right: 14px;
  top: 30px;
  letter-spacing: 2.5px;
  font-size: 20px;
  font-weight: 500;
}

.kr {
  position: absolute;
  right: 42%;
  bottom: 2%;
  letter-spacing: 2.5px;
  font-size: 26px;
  font-weight: 600;
  color: #fda000;
}

.attention-image {
  width: 15px;
  height: 15px;
}

.clock-count {
  color: #fda000;
  font-size: 80px;
  font-weight: 700;
}

.card-header-color {
  background: #00000008;
  color: #9c6eaa;
  border-radius: 10px 10px 0px 0px;
  padding: 15px;
  font-size: 40px;
  line-height: 54px;
}

.card-header-color-rating {
  background: #00000008;
  color: #9c6eaa;
  border-radius: 10px 10px 0px 0px;
  padding: 15px;
  font-size: 27px;
  line-height: 54px;
}
.card-header-color-rating-dashboard {
  background: #00000008;
  color: #9c6eaa;
  font-size: 20px;
  line-height: 54px;
}

.feedbacks-title {
  font-size: 24px;
  line-height: 32.4px;
  color: #9c6eaa;
  font-weight: 700;
  text-align: left;
  padding-left: 15px;
  margin-bottom: 0px !important;
}

.customer-Name {
  font-size: 42px;
  line-height: 57px;
  color: #000000cc;
  font-weight: 400;
  text-align: left;
  padding-left: 15px;
  margin-bottom: 0px !important;
}

.rating-card {
  width: 100%;
  height: 100%;
  background: #9c6eaa0c;
  font-weight: 600;
  font-size: 21px;
  border-radius: 18.1611px;
  padding: 10px !important;
  margin: 0 auto;
}

.table-header-color {
  color: #9c6eaa !important;
}

.text-description {
  font-size: 38px;
  line-height: 57.96px;
  color: #000000;
  font-weight: 400;
  text-align: left;
  padding-left: 15px;
  margin-bottom: 0px !important;
}

.card-avg-time {
  width: 710px;
  height: 113px;
  border-radius: 25px;
  padding: 0px !important;
  margin: 0px !important;
}

.avg-text {
  font-size: 12px;
  font-weight: 600;
  width: 50%;

  display: flex;
  align-items: center; /* centers items vertically */
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: #00000008;
  border-radius: 25px 0px 0px 25px;
  color: #9c6eaa;
}

.nodata-align {
  display: flex;
  align-items: center; /* centers items vertically */
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #9c6eaa;
  height: 100%;
  width: 100%;
}

/* Make the text italic and colored in a light gray */
.nodata-text {
  font-style: italic;
  color: #9c6eaa5e;
}

/* Add a moving animation to the text */
@keyframes shake {
  0% {
    transform: translate(0, 0) rotateZ(0);
  }
  20% {
    transform: translate(-10px, -10px) rotateZ(10deg);
  }
  40% {
    transform: translate(10px, -5px) rotateZ(-10deg);
  }
  60% {
    transform: translate(-10px, 0) rotateZ(10deg);
  }
  80% {
    transform: translate(10px, -5px) rotateZ(-10deg);
  }
  100% {
    transform: translate(0, 0) rotateZ(0);
  }
}

.nodata-text {
  /* Apply the animation on hover */
  animation: shake 0.8s ease-in-out;
  animation-iteration-count: 1;
}

.nodata {
  width: 200px;
  height: 200px;
}

.avg-time {
  font-size: 45px;
  font-weight: 700;
  width: 50%;
  height: 113px;
  display: flex;
  align-items: center; /* centers items vertically */
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #fda000;
  border-radius: 0px 25px 25px 0px;
}

.slik-logo-fornt {
  /* vertically center */
  height: 94.31537628173828px;
  width: 177.6347198486328px;
}

.slik-logo {
  /* vertically center */
  display: block !important;
  margin-left: auto !important;
  height: 94.31537628173828px;
  width: 177.6347198486328px;
  margin-bottom: 10px !important;
}

.progress-margin {
  background-color: #ffffff44 !important;
}

.feedbacks-button {
  position: relative;
  display: inline-block;
  background-color: #f3b200;
  border-radius: 10px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 20px;
  transition: all 0.6s ease-in-out;
  margin-top: -10px !important;
  transform: translateZ(0);
}

.arrow-button {
  position: relative;
  display: inline-block;
  background-color: #f3b200;
  border-radius: 10px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 20px;
  transition: all 0.6s ease-in-out;
  margin-top: -10px !important;
  transform: translateZ(0);
}
.arrow-button:hover {
  border-radius: 100px;
  background-color: #2f024d;
}

.indexing-number {
  font-size: 18px;
  font-weight: 700;
  color: #f3b200;
}

.feedbacks-button:hover {
  border-radius: 100px;
  width: 300px;
  padding: 10px 40px;
}

.feedbacks-button::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #ffffff;
  transition: all 0.6s ease-in-out;
}

.feedbacks-button:hover::after {
  right: 0px;
}
