.img-size {
  width: 190px;
  height: 220px;
  border: 1px solid #686868;
  border-radius: 5px;
  padding: 5px;
}

.info-text {
  font-size: 10px;
  font-style: italic;
  color: #7a7a7a;
  text-align: start;
}

.card {
  padding: 10px;
  box-shadow: 1px 1px 1px 1px #909090;
}

.form-label {
  text-align: left !important;
}

.image-round {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid #686868;
}
