.container-login {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 7rem;
  padding: 0 2rem;
}

.salarySearch {
  width: 200px;
  height: 130px;
}

.searchButton {
  width: 200px;
  height: 50px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #4036b6, #533cd2, #6132be);
  background-size: 200%;
  font-size: 1.2rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 33px 0;
  cursor: pointer;
  transition: 777ms;
}

.lastdaysButton {
  width: 150px;
  height: 50px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #0a83a1, #116d92, #0d7077);
  background-size: 200%;
  font-size: 0.9rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 33px 0;
  cursor: pointer;
  transition: 777ms;
}

.text-purple {
  color: #4036b6;
  font-size: 14px;
  font-weight: 700;
}

.input-width {
  width: 40% !important;
}

.login-fullwidth {
  background-image: url("../../assets/Rectangle.svg");
  background-size: cover;
  background-position: center;
}
.img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.login-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.img {
  width: 100%;
}

.form-login {
  width: 360px;
}

.login-content img {
  height: 100px;
}

.login-content h2 {
  margin: 15px 0;
  color: #c1c1c1;
  text-transform: uppercase;
  font-size: 2.9rem;
}
.shift-modal {
  color: blue;
  text-decoration: underline;
}

.shift-modal:hover {
  cursor: pointer;
  color: red;
  text-decoration: underline;
}

.loader-size {
  width: 110px;
  height: 120px;
}

.form-control-login {
  border-radius: 2px;
  border: none;
  border-bottom: 1px solid #382ef4;
  box-shadow: 0 1px 5px -2px rgba(0, 0, 0, 0.2);
}

.form-control-login:focus {
  box-shadow: 0 3px 6px -5px #382ef4;
  border-bottom: 3px solid #382ef4;
}

.form-group-login > label {
  font-size: 18px;
  font-weight: 600;

  color: #e9bf8c;
}

a {
  display: block;
  text-align: right;
  text-decoration: none;
  color: #fbfbfb;
  font-size: 0.9rem;
  transition: 0.3s;
}

a:hover {
  color: #ad3cee;
}

.btn-login {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #4036b6, #533cd2, #6132be);
  background-size: 200%;
  font-size: 1.2rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 1rem 0;
  cursor: pointer;
  transition: 777ms;
}
.btn-login:hover {
  background-position: right;
  display: block;
  background-image: linear-gradient(
    to left,
    rgb(21, 13, 240),
    #8130e4,
    rgb(143, 80, 245)
  );
  transform: scale(104%);
  color: #fff;
}

/* @media screen and (max-width: 1050px) {
  .container {
    grid-gap: 5rem;
  }
}

@media screen and (max-width: 1000px) {
  form {
    width: 290px;
  }

  .login-content h2 {
    font-size: 2.4rem;
    margin: 8px 0;
  }

  .img img {
    width: 400px;
  }
}

@media screen and (max-width: 900px) {
  .container {
    grid-template-columns: 1fr;
  }

  .img {
    display: none;
  }

  .wave {
    display: none;
  }

  .login-content {
    justify-content: center;
  }
} */
