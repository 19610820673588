.images-employee {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
  border: 3px solid #f3f3f3;
  box-shadow: 0 0 8px #ccc;
}

.btn-salary {
  background-color: #4caf50;
  border: none;
  height: 43px;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.btn-salary:hover {
  background-color: #45a049;
  color: purple;
}

.salary-modal-center {
  margin: 0 auto;
  width: 80%;
}

.attendance-modal-body {
  padding-bottom: 230px !important;
}
